<template>
  <v-dialog
    v-model="dialog"
    max-width="1440"
  >
    <close-btn @click="closeDialog()" overflow />

    <v-card flat tile>
      <div class="svg-container" v-html="src"></div>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data() {
    return {
      dialog: false,
    }
  },

  props: {
    src: String,
    show: Boolean,
  },

  methods: {
    showDialog() {
      this.dialog = true
    },

    closeDialog() {
      this.dialog = false
    }
  }
}

</script>

<style lang="scss">

.svg-container {
  svg {
    display: block;
    width: 100% !important;
    height: auto !important;
  }
}

</style>